import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import useBuildUrl from './useBuildUrl'
import { PATH_SEPARATOR } from '../util/constants'

const numberType = 'number'

const isNumberType = (value) => {
  return typeof value == numberType ? true : false
}

export default function useCustomNavigate() {
  const navigate = useNavigate()
  const buildUrl = useBuildUrl()

  /**
   * @param {String | Number} to Destination page or delta number
   * @param {{replace?: boolean, state?: object, isFullUrl?: boolean }} options
   * @returns {void}
   */

  const customNavigate = useCallback(
    (to, options) => {
      if (isNumberType(to)) {
        return navigate(to)
      } else if (to.charAt(0) === PATH_SEPARATOR && !options?.isFullUrl) {
        return navigate(buildUrl(to), options)
      } else {
        return navigate(to, options)
      }
    },
    [navigate, buildUrl]
  )

  return customNavigate
}
