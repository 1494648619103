import classNames from 'classnames'

export default function Container(props) {
  const { children, className, id } = props

  return (
    <div
      id={id}
      className={classNames(
        className,
        'max-w-8xl mx-auto px-3 sm:px-6 lg:px-8'
      )}
    >
      {children}
    </div>
  )
}
