import { createContext, useContext, useRef, useState } from 'react'
import { loadToken, saveToken } from '../util/tokenManager'
import {
  getAssets,
  fetchManifest,
  handleManifestVersion,
} from '../modules/assets'
import {
  CACHE_KEYS,
  LOCAL_BUCKET_URL,
  VEHICLE_LIST_INITIAL_PAGE,
} from '../util/constants'
import useIsMobile from './useIsMobile'

const AppStateContext = createContext()

export function AppStateProvider(props) {
  const [token, setToken] = useState(loadToken())
  const [assets, setAssets] = useState(null)
  const [fallbackAssets, setFallbackAssets] = useState(null)
  const [vehicleListPage, setVehicleListPage] = useState(
    VEHICLE_LIST_INITIAL_PAGE
  )
  const isMobile = useIsMobile()
  const [dealersFilter, setDealersFilter] = useState()
  const [reviewChecks, setReviewChecks] = useState()
  const [statusBarHeight, setStatusBarHeight] = useState(0)
  const vehicleListHeaderRef = useRef(null)

  const loadAssets = async (url) => {
    const manifest = await fetchManifest(url)
    const localManifest = await fetchManifest(LOCAL_BUCKET_URL)

    handleManifestVersion(CACHE_KEYS.manifestVersion, manifest?.version)
    handleManifestVersion(
      CACHE_KEYS.fallbackManifestVersion,
      localManifest?.version
    )

    const assets = await getAssets(manifest, url)
    const localAssets = await getAssets(localManifest, LOCAL_BUCKET_URL)

    setAssets(assets)
    setFallbackAssets(localAssets)
  }

  const wrappedSaveToken = (newToken, rememberToken = false) => {
    saveToken(newToken, isMobile || rememberToken)
    setToken(newToken)
  }

  return (
    <AppStateContext.Provider
      value={{
        assets,
        fallbackAssets,
        loadAssets,
        token,
        setToken: wrappedSaveToken,
        isLoggedIn: !!token,
        vehicleListPage,
        setVehicleListPage,
        dealersFilter,
        setDealersFilter,
        reviewChecks,
        setReviewChecks,
        vehicleListHeaderRef,
        statusBarHeight,
        setStatusBarHeight,
      }}
      {...props}
    />
  )
}

export default function useAppState() {
  const context = useContext(AppStateContext)
  if (!context) {
    throw new Error('useAppState must be used within a AppStateContextProvider')
  }

  return context
}
