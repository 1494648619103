import { INTERNAL_ROUTES } from '../../util/internalRoutes'
import { useTranslation } from 'react-i18next'
import TitleLayout from '../../layouts/TitleLayout/TitleLayout'
import get from 'lodash/get'
import { BUTTON_COLOR_TYPES } from '../../util/constants'
import useLocalFilterQuery from '../../hooks/useLocalFilterQuery'
import { useEffect, useState } from 'react'
import useFavouriteVehicleIds, {
  useFavouriteVehicles,
} from '../../hooks/useFavouriteVehicles'
import { Spinner } from 'asb-frontend'
import Button from '../../components/Button/Button'
import useCustomNavigate from '../../hooks/useCustomNavigate'
import useAppState from '../../hooks/useAppState'
import Container from '../../components/Container/Container'
import VehicleList from '../SRP/components/VehicleList'

const spinnerSize = 28

export default function FavouritesPage() {
  const { vehicles, loading, loadVehicles } = useFavouriteVehicles()
  const { t } = useTranslation('ui')
  const { favouriteVehicleIds } = useFavouriteVehicleIds()
  const navigate = useCustomNavigate()
  const { isLoggedIn } = useAppState()
  const [originOf, setOriginOf] = useState(undefined)

  const [deletedItems] = useState([])

  const { data: filtersResult } = useLocalFilterQuery()
  const latitude = get(filtersResult, 'filters.latitude')
  const longitude = get(filtersResult, 'filters.longitude')
  const unit = get(filtersResult, 'filters.distanceUnit')

  useEffect(() => {
    loadVehicles()
  }, [])

  useEffect(() => {
    if (latitude && longitude) {
      setOriginOf({
        latitude: latitude,
        longitude: longitude,
        unit,
      })
    }
  }, [latitude, longitude, unit])

  const getVehicles = () =>
    vehicles?.filter((item) => !deletedItems.includes(item.id)) || []

  return (
    <TitleLayout title={t('myFavourites')} backTo={INTERNAL_ROUTES.SRP}>
      <div className="md:w-limited mx-4 md:mx-auto mb-20 mt-5 md:mt-10 text-center">
        {loading && <Spinner size={spinnerSize} />}
        {!loading && !vehicles?.length && (
          <div className="flex flex-col w-full p-4 mt-2">
            <p className="text-2xl font-light mx-2 text-center">
              {t('favouritesEmpty')}
            </p>
            <p className="text-lg font-light mx-2 mb-2 mt-4 text-center">
              {t('browserAndSaveFavourites')}
            </p>
            <Button
              color={BUTTON_COLOR_TYPES.primary}
              className="justify-center mx-2 my-1"
              onClick={() => navigate(INTERNAL_ROUTES.SRP)}
            >
              {t('discoverAllTheCars')}
            </Button>
          </div>
        )}

        {!isLoggedIn && !loading && vehicles?.length > 0 && (
          <div className="flex w-full flex-col mb-4 py-4 px-2 border-b-2 border-gray-light-2">
            <p className="mx-2 text-center mb-4 text-2xl">
              {t('favouritesInOneClick')}
            </p>
            <p className="mx-2 text-center mb-4 font-light">
              {t('favouritesLogInToSee')}
            </p>
            <Button
              className="justify-center mx-2 my-1"
              onClick={() => navigate(INTERNAL_ROUTES.LOGIN)}
            >
              {t('login')}
            </Button>

            <Button
              color={BUTTON_COLOR_TYPES.primaryLight}
              className="justify-center mx-2 my-1"
              onClick={() => navigate(INTERNAL_ROUTES.REGISTER)}
            >
              {t('signUp')}
            </Button>
          </div>
        )}

        {!loading && getVehicles()?.length > 0 && (
          <div className="p-4">
            {getVehicles()?.length}{' '}
            {getVehicles()?.length === 1 ? t('carSaved') : t('carsSaved')}
          </div>
        )}
      </div>

      <Container className="pb-6">
        <VehicleList
          vehicles={getVehicles()}
          originOf={originOf}
          favouriteVehicleIds={favouriteVehicleIds}
        />
      </Container>
    </TitleLayout>
  )
}
