import React, { useRef, useEffect, useState } from 'react'

const svgImageType = 'image/svg+xml'

export default function SvgFromString({ svgString, ...props }) {
  const svgRef = useRef()
  const [svgAttributes, setSvgAttributes] = useState({})

  useEffect(() => {
    if (svgRef && svgString) {
      const attributes = {}

      try {
        const parset = new DOMParser()
        const svgDocument = parset.parseFromString(svgString, svgImageType)
        const svgNode = svgDocument.firstChild

        svgRef.current.innerHTML = svgNode.innerHTML

        const svgNodeAttributes = svgNode.attributes
        Array.from(svgNodeAttributes).forEach(
          (attribute) => (attributes[attribute.name] = attribute.value)
        )
      } catch (error) {
        console.error('Unable to parse SVG string:', error)
      }

      setSvgAttributes(attributes)
    }
  }, [svgRef, svgString])

  const SvgElement = <svg ref={svgRef} {...svgAttributes} {...props} />

  return svgString ? SvgElement : null
}
