import { useEffect, useState, lazy, Suspense } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { Toaster } from 'react-hot-toast'
import { SplashScreen } from '@capacitor/splash-screen'
import loadInitialSettings from './setup/loadInitialSettings'
import { createGraphQLClient } from './setup/graphql'
import { CartProvider } from './hooks/useCart'
import AppRoutes from './Routes'
import './setup/i18n'
import {
  setCampain,
  initializeTagManager,
} from './setup/tracking/trackingFunctions'
import { OneTrustButton } from './util/oneTrust'
import { AssetLoader } from './modules/assets'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { updateSrpInternalRoute } from './util/internalRoutes'
import {
  getStatusBarHeight,
  isNativePlatform,
  isNativeiOS,
} from './util/native/nativeUtils'
import useAppState from './hooks/useAppState'
import { InteractionStudioSalesforce } from './util/interactionStudioSalesforce'
import { loadCurrencyConfig } from './util/currencyHelper'
import { cleanInvalidRequests } from './util/fetchRequestWithCache'
import refreshVehicleFilterCache from './util/refreshVehicleFilterCache'
import addStyleMessageListener from './setup/addStyleMessageListener'
import { FirebaseMessagingProvider } from './modules/firebase-messaging'
import LoadErrorPage from './pages/LoadErrorPage/LoadErrorPage'

const LanguageModal = lazy(() => import('./popups/LanguageModal'))

export function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

function App() {
  const [graphQLClient, setGraphQLClient] = useState()
  const [initialized, setInitialized] = useState(false)
  const [initializeError, setInitializeError] = useState(null)
  const [showCrashPage, setShowCrashPage] = useState(false)
  const { setToken, setStatusBarHeight } = useAppState()
  const { t } = useTranslation('ui')

  const [searchParams] = useSearchParams()
  useEffect(() => {
    if (searchParams.entries().next().value) setCampain(searchParams)
  }, [searchParams])

  async function applyInitialSettings(settings) {
    const graphqlClient = await createGraphQLClient(
      settings.mobileService,
      setToken
    )
    setGraphQLClient(graphqlClient)
    refreshVehicleFilterCache(graphqlClient)

    updateSrpInternalRoute(settings.country)
    loadCurrencyConfig(settings.currencyConfig)
    initializeTagManager(settings.webappGtmId)
    addStyleMessageListener(settings.portalAdminUrls)
  }

  async function initializeWebapp() {
    setInitializeError(null)

    cleanInvalidRequests()

    try {
      const settings = await loadInitialSettings()
      await applyInitialSettings(settings)

      setInitialized(true)
      setShowCrashPage(false)
    } catch (error) {
      setInitializeError(error)
      setShowCrashPage(true)
    }
  }

  useEffect(async () => {
    await initializeWebapp()

    if (isNativePlatform()) {
      await SplashScreen.hide()
    }

    if (isNativeiOS()) {
      const statusBarHeight = await getStatusBarHeight()
      setStatusBarHeight(statusBarHeight)
    }
  }, [])

  if (showCrashPage) {
    return (
      <LoadErrorPage
        initializeWebapp={initializeWebapp}
        initializeError={initializeError}
      />
    )
  }

  if (!initialized) {
    return null
  }

  return (
    <>
      <Helmet>
        <title>{t('webTitle')}</title>
      </Helmet>
      <Toaster
        position="top-right"
        containerStyle={{
          top: 60,
        }}
      />
      <ApolloProvider client={graphQLClient}>
        <CartProvider>
          <AssetLoader />
          <Suspense fallback={null}>
            <LanguageModal />
          </Suspense>
          <OneTrustButton />
          <InteractionStudioSalesforce />
          <ScrollToTop />
          <AppRoutes />
          <FirebaseMessagingProvider />
        </CartProvider>
      </ApolloProvider>
    </>
  )
}

export default App
