import { AppConfig } from './environment'
import { DEFAULT_FILTER_DISTANCE_RADIUS } from './setup/cache/vehicleFilters'

const settings = {
  configUrl: `${AppConfig.API_HOST || 'http://localhost:8130'}/config`,
  translationsUrl: `${
    AppConfig.API_HOST || 'http://localhost:8130'
  }/translations`,
  platform: 'webapp',
  // country: 'US',
  marketplaceId: '7c7b19b5-790c-4a77-b605-b4370e9eb53b',
  // TODO: Remove default distance filter
  defaultDistanceFilter: 50,
  defaultDistanceRadiusFilters: DEFAULT_FILTER_DISTANCE_RADIUS,
  brandName: 'Wabi',
  requirePrivacyPolicyConsent: true,
  mobileService: AppConfig.API_HOST,
  initialConfigTTL: 600000,
  documentsTTL: 600,
  reCaptchaKey: AppConfig.RECAPTCHA_KEY,
}

export default settings
