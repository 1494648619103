import { useEffect, useState } from 'react'
import { getStatusBarHeight, isNativeiOS } from '../util/native/nativeUtils'

export function useSafeAreaMobile() {
  const [statusBarHeight, setStatusBarHeight] = useState(0)

  useEffect(() => {
    if (isNativeiOS()) {
      getStatusBarHeight().then((statusBarHeight) => {
        setStatusBarHeight(statusBarHeight)
      })
    }
  }, [])

  return { statusBarHeight }
}
