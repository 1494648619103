import { useQuery } from '@apollo/client'
import LOCAL_FILTER_QUERY from '../filters/localFilterQuery'
import { useMemo } from 'react'

function countFilters(filtersData) {
  let count = 0

  if (filtersData) {
    const { filters } = filtersData
    const {
      distance,
      availableNow,
      minPrice,
      maxPrice,
      makesModels,
      bodyStyles,
      fuelTypes,
      transmission,
    } = filters

    if (distance) {
      count++
    }

    if (availableNow) {
      count++
    }

    if (minPrice || maxPrice) {
      count++
    }

    count += makesModels.length
    count += bodyStyles.length
    count += fuelTypes.length
    count += transmission.length
  }

  return count
}

export default function useLocalFilterQuery(options) {
  const { data, loading } = useQuery(LOCAL_FILTER_QUERY, options)

  const filtersCount = useMemo(() => countFilters(data), [data])

  return { data, loading, filtersCount }
}
