import TextClickable from '../components/TextClickable/TextClickable'
import { navigateToExternalLink } from './navigateToExternalLink'
import settings from '../settings'
import i18n from '../setup/i18n'

const {
  CHECKS,
  SPAN_PREFIX,
  PERIOD_SYMBOL,
  EMPTY_STRING,
  HTML_BREAK_LINE,
  VALUES,
  TRANSLATIONS,
  DOC_PREFIX_TAG,
} = require('./constants')
const { verifyTextWithLinksFormat } = require('../util/format')
const underlineLink = true
const openInNewTab = true

export const getTranslatedGenders = (translationFunction) => {
  const { GENDERS } = require('./constants')

  const translatedGenders = translateValueLabel(translationFunction, GENDERS)

  return translatedGenders
}

export const translateIdentityDocumentTypes = (
  translationFunction,
  identityDocumentTypes
) => {
  const translatedIdentityDocumentsTypes = translateValueLabel(
    translationFunction,
    identityDocumentTypes
  )

  return translatedIdentityDocumentsTypes
}

export const translateValueLabel = (translationFunction, valuesAndLabels) => {
  const translated = valuesAndLabels?.map((element) => {
    return { value: element.value, label: translationFunction(element.label) }
  })

  return translated
}

const isDocumentTag = (documentTagObject) => documentTagObject.length > 1
const getDocumentTagObject = (tag) => tag.split(DOC_PREFIX_TAG)

export const getUrlFromTag = (tag, documentsUrls = {}) => {
  if (!tag) return tag
  const documentTagObject = getDocumentTagObject(tag)
  const documentTag = isDocumentTag(documentTagObject)
    ? documentTagObject[1]
    : documentTagObject[0]
  return documentsUrls && documentsUrls[documentTag]
    ? documentsUrls[documentTag]
    : documentTag
}

const translateLink = (match, line, row, documentsUrls) => {
  const { VALUE_ZERO } = require('./constants')
  const regexp = verifyTextWithLinksFormat
  const link = []
  let lastIndex = VALUE_ZERO
  if (row.length) {
    lastIndex = row[row.length - 1].end
  }
  if (match.index !== lastIndex) {
    link.push({
      type: CHECKS.LABEL_TYPE_TEXT,
      text: line.substring(lastIndex, match.index),
      start: lastIndex,
      end: match.index,
    })
  }
  link.push({
    type: CHECKS.LABEL_TYPE_LINK,
    text: match[1],
    url: getUrlFromTag(match[2], documentsUrls),
    start: match.index,
    end: regexp.lastIndex,
  })
  return link
}

const translateLine = (line, documentsUrls) => {
  const regexp = verifyTextWithLinksFormat
  const row = []
  let match
  while ((match = regexp.exec(line)) !== null) {
    const translatedLine = translateLink(match, line, row, documentsUrls)
    row.push(...translatedLine)
  }
  if (row.length) {
    const lastIndex = row[row.length - 1].end
    if (lastIndex !== line.length)
      row.push({
        type: CHECKS.LABEL_TYPE_TEXT,
        text: line.substring(lastIndex, line.length),
        start: lastIndex,
        end: line.length,
      })
    return row
  } else return [{ type: CHECKS.LABEL_TYPE_TEXT, text: line }]
}

export const translateTextWithLinks = (text, documentsUrls) => {
  const { VALUES } = require('./constants')
  const textLines = text.split(VALUES.lineBreak)
  const notices = textLines.map((line) => translateLine(line, documentsUrls))
  return notices
}

const getElementsToLinks = (lineElements, hoverLinkColor) => {
  if (lineElements.type === CHECKS.LABEL_TYPE_TEXT) {
    return lineElements.text === EMPTY_STRING
      ? HTML_BREAK_LINE
      : lineElements.text
  } else if (lineElements.type === CHECKS.LABEL_TYPE_LINK)
    return (
      <TextClickable
        text={lineElements.text}
        key={lineElements.url}
        hoverColor={hoverLinkColor}
        underline={underlineLink}
        onClick={() => navigateToExternalLink(lineElements.url, openInNewTab)}
      />
    )

  return null
}

const getMobileUrl = (fileUrl) =>
  settings.mobileUrls[fileUrl] === EMPTY_STRING
    ? null
    : settings.mobileUrls[fileUrl]

export const getFileUrl = (fileUrl, documentsUrls) =>
  getUrlFromTag(fileUrl, documentsUrls) || getMobileUrl(fileUrl) || fileUrl

export const changeTextToLinks = (
  val,
  documentsUrls,
  hoverLinkColor = EMPTY_STRING
) => {
  if (!val.length) return <></>
  const textTranslated = translateTextWithLinks(val, documentsUrls)
  return textTranslated.map((textLine, key) => (
    <>
      {textTranslated.length > 1 && <br />}
      <span key={`${SPAN_PREFIX}${key}`}>
        {textLine.map((lineElements) =>
          getElementsToLinks(lineElements, hoverLinkColor)
        )}
      </span>
    </>
  ))
}

const onMailClick = (supportEmail) => {
  window.location.href = `mailto:${supportEmail}`
}

export const getMailClick = () => {
  const supportEmail = settings?.supportEmail
  return (
    <>
      <TextClickable
        text={supportEmail}
        onClick={() => onMailClick(supportEmail)}
      />
      {PERIOD_SYMBOL}
    </>
  )
}

const regexUpperCaseLetters = /[A-Z]+/
export const hasUpperCaseLetter = (keyItem) =>
  keyItem.match(regexUpperCaseLetters)

export const hasSeparator = (keyItem) => keyItem.includes(VALUES.dash)

export const existsTranslationUrl = (keyItem) =>
  i18n.existsKey(TRANSLATIONS.URLS, keyItem)
