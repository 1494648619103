import { PLATFORMS } from './constants'
import { getPlatform } from './native/nativeUtils'

export const hasOneElement = (array) => array && array.length === 1
export const omitNotch = getPlatform() === PLATFORMS.ios

export const scriptExistsInHTML = (elementId) => {
  const scriptElement = document.getElementById(elementId)

  return scriptElement ? true : false
}
