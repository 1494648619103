import classNames from 'classnames'
import get from 'lodash/get'
import useSubscriber from '../../../hooks/useSubscriber'
import { tagManagerCarAddFavourite } from '../../../tracking/trackingFavourites'
import { AUTOMATION_IDS, VEHICLE_STATUS } from '../../../util/constants'
import VehicleFavourite from './VehicleFavourite'
import useIsMobile from '../../../hooks/useIsMobile'
import { Link } from 'react-router-dom'
import VehicleCard from './VehicleCard'
import useLocalFilterQuery from '../../../hooks/useLocalFilterQuery'
import { tagManagerCarListSelect } from '../../../tracking/trackingSRP'
import { getVehicleDetailsRoute } from '../../../util/internalRoutes'
import useBuildUrl from '../../../hooks/useBuildUrl'
import { memo } from 'react'

const getVehiclePrice = (vehicle, duration) => {
  const vehiclePricing =
    vehicle.pricing.find((pricing) => pricing.duration === duration) ||
    vehicle.pricing[0]

  return vehiclePricing?.bestPrice
}

function VehicleList({
  vehicles,
  originOf,
  favouriteVehicleIds,
  singleColumn,
}) {
  const isMobile = useIsMobile()
  const buildUrl = useBuildUrl()

  const { data } = useSubscriber()
  const currentSubscriberData = data?.currentSubscriber

  const { data: filtersResult } = useLocalFilterQuery()
  const duration = get(filtersResult, 'filters.duration')

  return (
    <div
      className={`grid gap-6
          ${singleColumn ? null : 'md:grid-cols-2 lg:grid-cols-3'}`}
      dataTestId={AUTOMATION_IDS.availableVehiclesList}
    >
      {vehicles.map((vehicle, index) => {
        const isNotAvailable =
          vehicle.status && vehicle.status !== VEHICLE_STATUS.AVAILABLE

        return (
          <div
            className="relative z-0 cursor-pointer overflow-hidden rounded-lg shadow-card"
            key={vehicle.id}
          >
            <VehicleFavourite
              id={vehicle.id}
              favouriteVehicleIds={favouriteVehicleIds}
              trackFunction={(isFavourite) =>
                tagManagerCarAddFavourite(
                  index,
                  vehicle,
                  isFavourite,
                  currentSubscriberData
                )
              }
              className={classNames(
                'absolute z-10 bg-white rounded-full p-2 m-2.5',
                isMobile ? '' : 'right-0',
                isNotAvailable && 'mt-8 lg:mt-2.5'
              )}
            />

            <Link
              dataTestId={`${AUTOMATION_IDS.availableVehicle}-${index}`}
              key={vehicle.id}
              id={`VE_${index}`}
              to={buildUrl(getVehicleDetailsRoute(vehicle))}
              onClick={() => {
                tagManagerCarListSelect(index, vehicle, currentSubscriberData)
              }}
            >
              <VehicleCard
                className={classNames(
                  'h-full srp-vehicle-card',
                  singleColumn ? null : 'md:flex-col vehicle-card-without-map'
                )}
                vehicleImageClassName={classNames(
                  'w-5/12',
                  singleColumn ? null : 'md:w-full vehicle-card-without-map'
                )}
                price={getVehiclePrice(vehicle, duration)}
                hideSortDetails
                showFromPriceText
                excludeCents
                originOf={originOf}
                showNotAvailable={isNotAvailable}
                vehicle={vehicle}
              />
            </Link>
          </div>
        )
      })}
    </div>
  )
}

export default memo(VehicleList)
