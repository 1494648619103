import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { parseFavouritesKey } from './useFavourites'
import useAppState from './useAppState'
import { useEffect } from 'react'
import { FETCH_POLICIES } from '../util/constants'

export const FAVOURITES_VEHICLE_QUERY = gql`
  query getFavouriteVehicles($input: GetFavoriteVehiclesInput!) {
    getFavouriteVehicles(input: $input) {
      vehicleIds
      vehicles {
        id
        year
        make
        model
        trim
        description
        status
        rideshareEligible
        co2Emission
        co2EfficiencyClass
        electricityConsumption
        combinedPetrolConsumption
        electricRange
        commonPricing {
          pricing {
            value
            duration
            durationUnit
            deductible {
              description
              amount
            }
            productType
            subscriptionTerms {
              term
              additionalPrice
              discountPercentage
              hidden
              displayPrice
            }
            mileageTerms {
              mileage
              additionalPrice
              isDefaultAdditionalRate
              additionalInterval
              additionalRate
              hidden
              displayPrice
            }
            bestTerm {
              term
              discountPercentage
              additionalPrice
            }
            bestPrice
            displayPrice
          }
          mileagePricing {
            included
            unlimited
            additionalRate
            additionalInterval
          }
        }
        location {
          id
          name
          address {
            address1
            address2
            city
            state
            zip
            country
          }
          latitude
          longitude
        }
        featureImage {
          url
        }
        images {
          url
        }
        priceBreakdown {
          percentages {
            name
            value
          }
        }
        exteriorColorName
        exteriorColorHex
        interiorColorName
        interiorColorHex
        odometer
        odometerUnit
        fuelType
        transmission
        bodyStyle
        driveType
        horsepower
        dealer {
          id
          name
        }
        engineSize
        engineKw
        gearsNumber
        priceBrutto
        equipment {
          description
        }
        mpgMixed
        seats
        doors
        setupDelay
        deposit
        downPayment
        finalPayment
        disabled
      }
    }
  }
`

export function useFavouriteVehicles() {
  const { isLoggedIn } = useAppState()

  const {
    data: serverResult,
    loading,
    refetch,
  } = useQuery(FAVOURITES_VEHICLE_QUERY, {
    variables: {
      input: { vehicleIds: !isLoggedIn ? parseFavouritesKey() : [] },
    },
    fetchPolicy: FETCH_POLICIES.noCache,
  })

  const vehiclesResponse = serverResult?.getFavouriteVehicles?.vehicles || []

  const vehicles = vehiclesResponse.map(({ commonPricing, ...vehicle }) => {
    const { pricing, mileagePricing } = commonPricing
    vehicle.pricing = pricing
    vehicle.mileagePricing = mileagePricing

    return vehicle
  })

  return {
    vehicles,
    loading,
    loadVehicles: refetch,
  }
}

export default function useFavouriteVehicleIds() {
  const { isLoggedIn } = useAppState()

  const [getFavourites, { data: serverResult, loading }] = useLazyQuery(
    FAVOURITES_VEHICLE_QUERY,
    {
      variables: {
        input: { vehicleIds: [] },
      },
      fetchPolicy: FETCH_POLICIES.noCache,
    }
  )

  useEffect(() => {
    if (isLoggedIn) {
      getFavourites()
    }
  }, [isLoggedIn])

  if (!isLoggedIn) {
    return {
      favouriteVehicleIds: parseFavouritesKey(),
      refreshFavourites: getFavourites,
      loading,
    }
  }

  if (serverResult?.getFavouriteVehicles?.vehicleIds?.length) {
    return {
      favouriteVehicleIds: serverResult?.getFavouriteVehicles?.vehicleIds,
      refreshFavourites: getFavourites,
      loading,
    }
  }

  return {
    favouriteVehicleIds: [],
    refreshFavourites: getFavourites,
    loading,
  }
}
