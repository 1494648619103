export const trackingLiterals = {
  gtmId: 'GTM-59HBLRM',
  page: {
    category: {
      home: 'home',
      loggin: 'Loggin',
      register: 'Register',
      carlist: 'carlist',
      interaction: 'Interaction',
      vdp: 'car detail',
      cart_detail: 'cart detail',
      checkout: 'checkout',
      purchase: 'purchase',
      app_purchase: 'app_purchase',
      payment_details: 'Payment Details',
      profile: 'Profile',
      billingHistory: 'Billing History',
      subscription: 'Subscription Details',
      confirm_identity: 'Confirm Identity',
      my_account: 'My Account',
      coupon: 'Coupon',
      payment: 'Payment',
      step_personal_info: 'step_personal_info',
      step_id_info: 'step_id_info',
      driver_license_start: 'driver_license_start',
      driver_license_finish: 'driver_license_finish',
      cardetail: 'cardetail',
      contract_signature: 'contract_signature',
      otp_verification: 'otp_verification',
      verification: 'verification',
    },
    type: {
      home: 'home',
      loggin: 'loggin',
      resetpasword: 'resetpasword',
      list: 'list',
      register: 'register',
      vdp: 'product detail',
      checkout: 'checkout',
      profile: 'Profile',
      private_area: 'area Privada',
      billingHistory: 'Billing History',
      subscription: 'Subscription Details',
      privateArea: 'Private Area',
      car_detail: 'car Detail',
      otp_verification: 'otp_verification',
    },
    name: {
      home: 'wabi car home inicio',
      carlist: 'Our Cars',
      login: 'wabi car iniciar sesion',
      resetpasword: 'reset-password',
      register: 'registration',
      checkout: 'continue to purchase',
      profile: 'Profile account page',
      invoices: 'facturacion',
      suscription: 'My Suscriptions',
      confirm_identity: 'Confirm Identity',
      my_webicar: 'My Wabicar',
      invoice: 'Invoice - List',
      invoice_detail: 'Invoice - Detail',
      credit_card_detail: 'My Credit Card - Detail',
      show_all: 'Show All',
      pay_renew: 'Pay And renew',
      more_info: 'More Info',
      contract: 'Contract',
      otp_code_entry: 'otp_code_entry',
    },
  },
  events: {
    page_view_event: 'analytics_page_view',
    page_event: 'analytics_event',
    select_item: 'select_item',
    view_item_list: 'view_item_list',
    view_item: 'view_item',
    add_to_favorites: 'add_to_favorites',
    remove_from_favorites: 'remove_from_favorites',
    add_to_cart: 'add_to_cart',
    view_cart: 'view_cart',
    add_payment_info: 'add_payment_info',
    personal_info: 'personal_info',
    begin_checkout: 'begin_checkout',
    purchase: 'purchase',
    download_google_play: 'download_google_play',
    download_app_store: 'download_app_store',
    my_account: 'my_account',
    preference_center: 'preference_center',
    agree: 'agree',
    save: 'save',
    login: 'login',
    forgot_password: 'forgot_password',
    sign_in: 'sign_in',
    sign_up: 'sign_up',
    discover_all_cars: 'discover_all_cars',
    view_overdue_bill: 'view_overdue_bill',
    contact_support: 'contact_support',
    pay_overdue_bill: 'pay_overdue_bill',
    cancel_delivery: 'cancel_delivery',
    edit_profile: 'edit_profile',
    payment_information: 'payment_information',
    legal: 'legal',
    my_subscription: 'my_subscription',
    support: 'support',
    log_out: 'log_out',
    continue_registration: 'continue_registration',
    already_have_account: 'already_have_account',
    decision_validation: 'Decision-Validacion',
    validation: 'Validacion',
    error_validation: 'Error-Validacion',
    identity_verification: 'identity_verification',
    add_payment_option: 'add_payment_option',
    select_payment_method: 'select_payment_method',
    contract_signature: 'contract_signature',
    otp_code_verification: 'otp_code_verification',
    refund: 'refund',
    categories: {
      interaction: 'Interaction',
      Conversion: 'Conversion',
    },
    actions: {
      menu: 'menu',
      button: 'button',
      select: 'select',
      add: 'add',
      date: 'date',
      filters: 'filter',
      deliveryDate: 'DeliveryDate',
      deliveryTime: 'DeliveryTime',
      contractAgreement: 'ContractAgreement',
      agree: 'Agree',
      save: 'save',
      subscriptionDetails: 'SubscriptionDetails',
      billingDetails: 'BillingDetails',
      billingPayment: 'BillingPayment',
      swipeFrame: 'swipeFrame',
      swipe: 'swipe',
      close: 'close',
      ctaCarousel: 'CTA: your perfect match / Start',
      mapFilter: 'MAP FILTER',
      openArea: 'Open Area',
      dealerName: 'Dealer Name',
      conversion: 'Conversion',
      filterBy: 'Filter by',
      myAccount: 'MyAccount',
      coupon: 'Coupon',
      signed: 'signed',
    },
    payment_status: {
      failed: 'failed',
      paid: 'paid',
    },
  },
  ecommerce: {
    NewSuscription: 'NewSuscription',
    Renewal: 'Renewal',
    pickUp: 'pick up',
    monthsSubscription: 'MonthsSubscription',
    familiares: 'Familiares',
    itemListId: 'SRPVehicleList',
    ListNameFamiliares: 'Familiares',
    ListIndexPrefix: 'VE_',
    CategoryPrefix: 'WABI_',
  },
  ABS: {
    notAvailable: 'n/a',
    yes: 'yes',
    no: 'no',
  },
  numbers: {
    one: '1',
  },
}
