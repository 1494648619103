import classNames from 'classnames'
import FragmentOpacityTransition from '../FragmentOpacityTransition/FragmentOpacityTransition'

const classNameOverlay = 'fixed z-20 inset-0 bg-surface-overlay bg-opacity-50'

export default function OverlayDiv({ children, className, show }) {
  return (
    <FragmentOpacityTransition show={show}>
      <div className={classNames(className, classNameOverlay)}>{children}</div>
    </FragmentOpacityTransition>
  )
}
