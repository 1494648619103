import axios from 'axios'
import settings from '../settings'
import i18n from './i18n'
import { LanguageConfig } from 'f1-utils'
import { getAppId, getPlatform } from '../util/multiTenantHelper'
import { TenantCustomHeaders } from '../util/constants'
import { localStorageByCountry } from '../util/storageByCountry'
import { CACHE_KEYS, StatusCodes, QUERY_PARAM_NAMES } from '../util/constants'
import { millisecondsToHours } from '../util/generalOperations'

const requestHeaders = {
  'tenant-id': settings.marketplaceId,
  [TenantCustomHeaders.APP]: getPlatform(),
}

const getConfig = (key, params, url) => {
  const cachedConfig = localStorageByCountry.getItem(key)
  if (!cachedConfig) {
    return axios.get(url, {
      params,
      headers: requestHeaders,
    })
  }
  return JSON.parse(cachedConfig)
}

const addTranslationsToBundle = (translations) => {
  Object.keys(translations).forEach((lang) => {
    if (translations[lang])
      Object.keys(translations[lang]).forEach((ns) => {
        const internalLanguageCode =
          LanguageConfig.I18_MAPPING[LanguageConfig.I18_MAPPING_REVERSE[lang]]

        i18n.addResourceBundle(
          internalLanguageCode,
          ns,
          translations[lang][ns],
          true,
          true
        )
      })
  })
}

const updateTranslations = (translationsResponse) => {
  try {
    const updatedTranslations = translationsResponse?.data || {}
    if (updatedTranslations.translations) {
      addTranslationsToBundle(updatedTranslations.translations)
      delete updatedTranslations.translations
    }
  } catch (err) {}
}

export default async function loadInitialSettings() {
  const appId = await getAppId()
  const configParams = {
    appId: appId,
  }
  const translationsParams = {
    platform: settings.platform,
    appId: appId,
  }

  const timestamp = new Date().valueOf()
  const timestampInHours = millisecondsToHours(timestamp)
  const configUrl = `${settings.configUrl}?${QUERY_PARAM_NAMES.hourstamp}=${timestampInHours}`
  const configPromise = getConfig(CACHE_KEYS.config, configParams, configUrl)
  const translationsPromise = getConfig(
    CACHE_KEYS.translations,
    translationsParams,
    settings.translationsUrl
  )

  const [configResponse, translationsResponse] = await Promise.all([
    configPromise,
    translationsPromise,
  ])

  if (configResponse?.status === StatusCodes.OK) {
    localStorageByCountry.setItem(
      CACHE_KEYS.config,
      JSON.stringify({
        data: configResponse.data,
        expiry: Date.now() + settings.initialConfigTTL,
      })
    )
  }

  if (translationsResponse?.status === StatusCodes.OK) {
    localStorageByCountry.setItem(
      CACHE_KEYS.translations,
      JSON.stringify({
        data: translationsResponse.data,
        expiry: Date.now() + settings.initialConfigTTL,
      })
    )
  }

  updateTranslations(translationsResponse)

  Object.assign(settings, configResponse.data)

  return settings
}
