import { Capacitor } from '@capacitor/core'
import { Device } from '@capacitor/device'
import { App } from '@capacitor/app'
import { EMPTY_STRING, MAX_MOBILE_WIDTH, PLATFORMS } from '../constants'
import { SafeArea } from 'capacitor-plugin-safe-area'

export function isNativePlatform() {
  return Capacitor.isNativePlatform()
}

export function isMobileOrNativePlatform() {
  return Capacitor.isNativePlatform() || window.innerWidth <= MAX_MOBILE_WIDTH
}

export function getPlatform() {
  return Capacitor.getPlatform()
}

export function isNativeAndroid() {
  return isNativePlatform() && getPlatform() === PLATFORMS.android
}

export function isNativeiOS() {
  return isNativePlatform() && getPlatform() === PLATFORMS.ios
}

export async function getOperatingSystem() {
  try {
    const deviceInfo = await Device.getInfo()
    return deviceInfo?.operatingSystem
  } catch (error) {
    return EMPTY_STRING
  }
}

export async function getAppVersion() {
  const appInfo = await App.getInfo()
  const versionNumber = appInfo.version

  return versionNumber
}

export async function getDeviceLanguageCode() {
  const languageCode = await Device.getLanguageCode()

  return languageCode.value
}

export async function getStatusBarHeight() {
  let statusBarHeight

  try {
    const statusBarInfo = await SafeArea.getStatusBarHeight()
    statusBarHeight = statusBarInfo.statusBarHeight
  } catch (error) {
    statusBarHeight = 0
  }

  return statusBarHeight
}
