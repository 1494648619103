import { lazy, Suspense, useEffect } from 'react'
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useParams,
} from 'react-router-dom'
import useAppState from './hooks/useAppState'
import useLanguageManager from './hooks/useLanguageManager'
import {
  INTERNAL_ROUTES,
  getSrpPathByCountry,
  srpPathByCountry,
} from './util/internalRoutes'
import useBuildUrl from './hooks/useBuildUrl'
import useSubscriber from './hooks/useSubscriber'
import LoadingLayout from './layouts/LoadingLayout/LoadingLayout'
import settings from './settings'
import {
  VALUES,
  SUBSCRIBER_STATUS,
  INTERNAL_ROUTES_KEYS,
} from './util/constants'
import OnboardingCarousel from './pages/OnboardingCarousel/OnboardingCarousel'
import { LanguageConfig } from 'f1-utils'
import useFetchLanguage from './hooks/useFetchLanguage'
import i18n from './setup/i18n'
import { isNativePlatform } from './util/native/nativeUtils'
import { acceptNewChecks } from './util/checks'
import useCustomNavigate from './hooks/useCustomNavigate'
import FavouritesPage from './pages/FavouritesPage/FavouritesPage'
import UpdatePopup from './popups/UpdatePopup'
import useAppNeedsUpdate from './hooks/useAppNeedsUpdate'
import moment from 'moment'

const DashboardPage = lazy(() => import('./pages/DashboardPage/DashboardPage'))
const LoginPage = lazy(() => import('./pages/LoginPage/LoginPage'))
const LoginSSOPage = lazy(() => import('./pages/LoginPage/LoginSSOPage'))
const ResetPasswordPage = lazy(() =>
  import('./pages/ResetPasswordPage/ResetPasswordPage')
)
const RegisterPage = lazy(() => import('./pages/RegisterPage/RegisterPage'))
const ValidateEmailPage = lazy(() =>
  import('./pages/ValidateEmailPage/ValidateEmailPage')
)
const DocumentUploadPage = lazy(() =>
  import('./pages/DocumentUploadPage/DocumentUploadPage')
)
const SRP = lazy(() => import('./pages/SRP/SRP'))

const VDP = lazy(() => import('./pages/VDP/VDP'))
const CheckoutPage = lazy(() => import('./pages/CheckoutPage/CheckoutPage'))
const CheckoutRenewalPage = lazy(() =>
  import('./pages/CheckoutPage/CheckoutRenewalPage')
)
const PaymentPage = lazy(() => import('./pages/PaymentPage/PaymentPage'))
const PaymentCardPage = lazy(() =>
  import('./pages/PaymentCardPage/PaymentCardPage')
)
const ConfirmCheckoutPage = lazy(() =>
  import('./pages/ConfirmCheckoutPage/ConfirmCheckoutPage')
)

const CheckoutScaPage = lazy(() =>
  import('./pages/CheckoutScaPage/CheckoutScaPage')
)
const EditProfilePage = lazy(() =>
  import('./pages/EditProfilePage/EditProfilePage')
)
const BillsPage = lazy(() => import('./pages/BillsPage/BillsPage'))
const BillDetailsPage = lazy(() =>
  import('./pages/BillDetailsPage/BillDetailsPage')
)
const ConfirmIdentityPage = lazy(() =>
  import('./pages/ConfirmIdentityPage/ConfirmIdentityPage')
)

const BankIdRedirectPage = lazy(() =>
  import('./pages/ConfirmIdentityPage/components/BankId/BankIdRedirectPage')
)

const MySubscriptionPage = lazy(() =>
  import('./pages/MySubscriptionPage/MySubscriptionPage')
)

const MySubscriptionsPage = lazy(() =>
  import('./pages/MySubscriptionsPage/MySubscriptionsPage')
)

const RedirectFromStripePage = lazy(() =>
  import('./pages/RedirectFromStripePage/RedirectFromStripePage')
)
const ProfilePage = lazy(() => import('./pages/ProfilePage/ProfilePage'))
const EIDValidatePhonePage = lazy(() =>
  import('./pages/EIDValidatePhonePage/EIDValidatePhonePage')
)
const EditCommunicationConsentsPage = lazy(() =>
  import('./pages/EditComunicationConsentsPage/EditCommunicationConsentsPage')
)
const CheckoutSSOPage = lazy(() =>
  import('./pages/CheckoutSSOPage/CheckoutSSOPage')
)
const MyCouponsPage = lazy(() => import('./pages/MyCouponsView/MyCouponsPage'))
const ConsentChecks = lazy(() => import('./pages/ConsentPage/ConsentPage'))
const UnderReview = lazy(() =>
  import('./pages/UnderReviewPage/UnderReviewPage')
)
const AccessDenied = lazy(() =>
  import('./pages/AccessDeniedPage/AccessDeniedPage')
)
const NewRegistrationChecks = lazy(() =>
  import('./pages/NewRegistrationChecks/NewRegistrationChecks')
)

const FiltersPage = lazy(() => import('./pages/FiltersPage/FiltersPage'))

const ExperianCheckPage = lazy(() =>
  import('./pages/ExperianCheckPage/ExperianCheckPage')
)
const UserCreditDocumentPage = lazy(() =>
  import('./pages/UserCreditDocumentPage/UserCreditDocumentPage')
)

const CheckProtectedAccess = (subStatus, isProtected) =>
  subStatus === SUBSCRIBER_STATUS.NOT_LOGGED && isProtected

const getNewRedirectRoute = (checkProtected, redirectUrl) => {
  let newRoute
  let allowRouteAccess = true
  if (checkProtected) {
    allowRouteAccess = false
    if (redirectUrl) {
      newRoute = redirectUrl
    } else {
      newRoute = INTERNAL_ROUTES_KEYS.LOGIN
    }
  } else {
    if (redirectUrl) {
      allowRouteAccess = false
      newRoute = redirectUrl
    }
  }
  return { allowRouteAccess, newRoute }
}
const CheckPrivateRoute = (subscriberStatus, element, blockedRouteData) => {
  const redirectUrl = blockedRouteData?.status[subscriberStatus]?.redirectUrl
  const checkProtected = CheckProtectedAccess(
    subscriberStatus,
    element.isProtected
  )
  const { allowRouteAccess, newRoute } = getNewRedirectRoute(
    checkProtected,
    redirectUrl
  )

  return { allowRouteAccess, newRoute }
}

const WebRoute = (props) => {
  const { subscriberStatus, element, blockedRoutes, isLoggedIn, reviewChecks } =
    props
  const location = useLocation()
  const buildUrl = useBuildUrl()
  const navigate = useCustomNavigate()
  const { pathname } = useLocation()

  const blockedRouteData = blockedRoutes?.find(
    (route) => INTERNAL_ROUTES[route.page] === element.page
  )

  const { allowRouteAccess, newRoute } = CheckPrivateRoute(
    subscriberStatus,
    element,
    blockedRouteData
  )

  useEffect(() => {
    if (isLoggedIn && pathname && reviewChecks === true) {
      acceptNewChecks(navigate)
    }
  }, [isLoggedIn, pathname, reviewChecks])

  return allowRouteAccess ? (
    <Suspense fallback={renderLoader()}>{element.component}</Suspense>
  ) : (
    <Navigate
      to={buildUrl(INTERNAL_ROUTES[newRoute])}
      state={{ from: location }}
      replace={props.replace}
    />
  )
}

function Index() {
  const { countryCode } = useParams()
  const { isLoggedIn } = useAppState()
  const [getUserLang, selectedUserLang] = useFetchLanguage()

  const country = countryCode || settings.country.toLowerCase()
  const language =
    selectedUserLang ||
    LanguageConfig.I18_MAPPING[settings.availableLanguages[0].languageConstant]

  useEffect(() => {
    i18n.changeLanguage(language)
    if (isLoggedIn) getUserLang()
  }, [isLoggedIn])

  if (isLoggedIn && !selectedUserLang) return null
  return <Navigate to={`/${country}/${language}/`} />
}

const renderLoader = () => <LoadingLayout />

const formatRoute = (route) => {
  return VALUES.slash + route?.split(VALUES.slash)[1]
}

const isWhitelistRoute = (route) => {
  const whiteList = settings?.availableRoutesWhitelist
  return whiteList?.length
    ? whiteList.some((item) => formatRoute(route) === item)
    : true
}

const GetRoutes = (
  subscriberStatus,
  blockedRoutes,
  isLoggedIn,
  reviewChecks
) => {
  const dynamicRoutes = [
    {
      page: INTERNAL_ROUTES.INDEX,
      path: '/',
      component: <Index />,
      isProtected: false,
    },
    {
      page: INTERNAL_ROUTES.INDEX,
      path: ':countryCode/',
      component: <Index />,
      isProtected: false,
    },
    {
      page: INTERNAL_ROUTES.DASHBOARD,
      path: `:countryCode/:lang${INTERNAL_ROUTES.DASHBOARD}`,
      component: <DashboardPage />,
      isProtected: false,
    },
    {
      page: INTERNAL_ROUTES.LOGIN,
      path: `:countryCode/:lang${INTERNAL_ROUTES.LOGIN}`,
      component: <LoginPage />,
      isProtected: false,
      countryCode: settings?.country,
    },
    {
      page: INTERNAL_ROUTES.FILTERS,
      path: `:countryCode/:lang${INTERNAL_ROUTES.FILTERS}`,
      component: <FiltersPage />,
      isProtected: false,
      countryCode: settings?.country,
    },
    {
      page: srpPathByCountry.default,
      path: `:countryCode/:lang${getSrpPathByCountry(settings?.country)}`,
      component: <SRP />,
      isProtected: false,
      countryCode: settings?.country,
    },
    {
      page: INTERNAL_ROUTES.VDP,
      path: `:countryCode/:lang${INTERNAL_ROUTES.VDP}`,
      component: <VDP />,
      isProtected: false,
    },
    {
      page: INTERNAL_ROUTES.SSO_LOGIN,
      path: `:countryCode/:lang${INTERNAL_ROUTES.SSO_LOGIN}`,
      component: <LoginSSOPage />,
      isProtected: false,
    },
    {
      page: INTERNAL_ROUTES.VALIDATE_EMAIL,
      path: `:countryCode/:lang${INTERNAL_ROUTES.VALIDATE_EMAIL}`,
      component: <ValidateEmailPage />,
      isProtected: false,
    },
    {
      page: INTERNAL_ROUTES.RESET_PASSWORD,
      path: `:countryCode/:lang${INTERNAL_ROUTES.RESET_PASSWORD}`,
      component: <ResetPasswordPage />,
      isProtected: false,
    },
    {
      page: INTERNAL_ROUTES.FAVOURITES,
      path: `:countryCode/:lang${INTERNAL_ROUTES.FAVOURITES}`,
      component: <FavouritesPage />,
      isProtected: false,
    },
    {
      page: INTERNAL_ROUTES.REGISTER,
      path: `:countryCode/:lang${INTERNAL_ROUTES.REGISTER}`,
      component: <RegisterPage />,
      isProtected: false,
    },
    {
      page: INTERNAL_ROUTES.CHECKOUT,
      path: `:countryCode/:lang${INTERNAL_ROUTES.CHECKOUT}`,
      component: <CheckoutPage />,
      isProtected: true,
    },
    {
      page: INTERNAL_ROUTES.DOCUMENT_UPLOAD,
      path: `:countryCode/:lang${INTERNAL_ROUTES.DOCUMENT_UPLOAD}`,
      component: <DocumentUploadPage />,
      isProtected: true,
    },
    {
      page: INTERNAL_ROUTES.CHECKOUT_SSO,
      path: `:countryCode/:lang${INTERNAL_ROUTES.CHECKOUT_SSO}`,
      component: <CheckoutSSOPage />,
      isProtected: true,
    },
    {
      page: INTERNAL_ROUTES.CHECKOUT_RENEW,
      path: `:countryCode/:lang${INTERNAL_ROUTES.CHECKOUT_RENEW}`,
      component: <CheckoutRenewalPage />,
      isProtected: true,
    },
    {
      page: INTERNAL_ROUTES.CHECKOUT_PAYMENT,
      path: `:countryCode/:lang${INTERNAL_ROUTES.CHECKOUT_PAYMENT}`,
      component: <PaymentPage />,
      isProtected: true,
    },
    {
      page: INTERNAL_ROUTES.CHECKOUT_PAYMENT_CARD,
      path: `:countryCode/:lang${INTERNAL_ROUTES.CHECKOUT_PAYMENT_CARD}`,
      component: <PaymentCardPage />,
      isProtected: true,
    },
    {
      page: INTERNAL_ROUTES.CHECKOUT_CONFIRM,
      path: `:countryCode/:lang${INTERNAL_ROUTES.CHECKOUT_CONFIRM}`,
      component: <ConfirmCheckoutPage />,
      isProtected: true,
    },
    {
      page: INTERNAL_ROUTES.CHECKOUT_VALIDATE_PHONE,
      path: `:countryCode/:lang${INTERNAL_ROUTES.CHECKOUT_VALIDATE_PHONE}`,
      component: <EIDValidatePhonePage />,
      isProtected: true,
    },
    {
      page: INTERNAL_ROUTES.PAYMENT_SCA,
      path: `:countryCode/:lang${INTERNAL_ROUTES.PAYMENT_SCA}`,
      component: <CheckoutScaPage />,
      isProtected: true,
    },
    {
      page: INTERNAL_ROUTES.EDIT_PROFILE,
      path: `:countryCode/:lang${INTERNAL_ROUTES.EDIT_PROFILE}`,
      component: <EditProfilePage />,
      isProtected: true,
    },
    {
      page: INTERNAL_ROUTES.MY_ACCOUNT,
      path: `:countryCode/:lang${INTERNAL_ROUTES.MY_ACCOUNT}`,
      component: <ProfilePage />,
      isProtected: true,
    },
    {
      page: INTERNAL_ROUTES.MY_SUBSCRIPTION,
      path: `:countryCode/:lang${INTERNAL_ROUTES.MY_SUBSCRIPTION}`,
      component: <MySubscriptionPage />,
      isProtected: true,
    },
    {
      page: INTERNAL_ROUTES.MY_SUBSCRIPTION,
      path: `:countryCode/:lang${INTERNAL_ROUTES.MY_SUBSCRIPTION}/:subscriptionId`,
      component: <MySubscriptionPage />,
      isProtected: true,
    },
    {
      page: INTERNAL_ROUTES.MY_SUBSCRIPTIONS,
      path: `:countryCode/:lang${INTERNAL_ROUTES.MY_SUBSCRIPTIONS}`,
      component: <MySubscriptionsPage />,
      isProtected: true,
    },
    {
      page: INTERNAL_ROUTES.BILLS,
      path: `:countryCode/:lang${INTERNAL_ROUTES.BILLS}`,
      component: <BillsPage />,
      isProtected: true,
    },
    {
      page: INTERNAL_ROUTES.BILL_DETAILS,
      path: `:countryCode/:lang${INTERNAL_ROUTES.BILL_DETAILS}`,
      component: <BillDetailsPage />,
      isProtected: true,
    },
    {
      page: INTERNAL_ROUTES.BILLS_PAYMENT_CARD,
      path: `:countryCode/:lang${INTERNAL_ROUTES.BILLS_PAYMENT_CARD}`,
      component: <PaymentCardPage />,
      isProtected: true,
    },
    {
      page: INTERNAL_ROUTES.CONFIRM_IDENTITY,
      path: `:countryCode/:lang${INTERNAL_ROUTES.CONFIRM_IDENTITY}`,
      component: <ConfirmIdentityPage />,
      isProtected: true,
    },
    {
      page: INTERNAL_ROUTES.BANK_ID_RETURN,
      path: `:countryCode/:lang${INTERNAL_ROUTES.BANK_ID_RETURN}`,
      component: <BankIdRedirectPage />,
      isProtected: true,
    },
    {
      page: INTERNAL_ROUTES.STRIPE_CHECKOUT_RETURN,
      path: `:countryCode/:lang${INTERNAL_ROUTES.STRIPE_CHECKOUT_RETURN}`,
      component: <RedirectFromStripePage />,
      isProtected: true,
    },
    {
      page: INTERNAL_ROUTES.MY_COMUNICATION_PREFERENCES,
      path: `:countryCode/:lang${INTERNAL_ROUTES.MY_COMUNICATION_PREFERENCES}`,
      component: <EditCommunicationConsentsPage />,
      isProtected: true,
    },
    {
      page: INTERNAL_ROUTES.MY_COUPONS,
      path: `:countryCode/:lang${INTERNAL_ROUTES.MY_COUPONS}`,
      component: <MyCouponsPage />,
      isProtected: true,
    },
    {
      page: INTERNAL_ROUTES.CONSENT_CHECKS,
      path: `:countryCode/:lang${INTERNAL_ROUTES.CONSENT_CHECKS}`,
      component: <ConsentChecks />,
      isProtected: true,
    },
    {
      page: INTERNAL_ROUTES.UNDER_REVIEW,
      path: `:countryCode/:lang${INTERNAL_ROUTES.UNDER_REVIEW}`,
      component: <UnderReview />,
      isProtected: true,
    },
    {
      page: INTERNAL_ROUTES.USER_CREDIT_DOCUMENT,
      path: `:countryCode/:lang${INTERNAL_ROUTES.USER_CREDIT_DOCUMENT}`,
      component: <UserCreditDocumentPage />,
      isProtected: true,
    },
    {
      page: INTERNAL_ROUTES.EXPERIAN_CHECK,
      path: `:countryCode/:lang${INTERNAL_ROUTES.EXPERIAN_CHECK}`,
      component: <ExperianCheckPage />,
      isProtected: true,
    },
    {
      page: INTERNAL_ROUTES.ACCESS_DENIED,
      path: `:countryCode/:lang${INTERNAL_ROUTES.ACCESS_DENIED}`,
      component: <AccessDenied />,
      isProtected: true,
    },
    {
      page: INTERNAL_ROUTES.ACCEPT_NEW_REGISTER_CHECKS,
      path: `:countryCode/:lang${INTERNAL_ROUTES.ACCEPT_NEW_REGISTER_CHECKS}`,
      component: <NewRegistrationChecks />,
      isProtected: true,
    },
  ]

  if (isNativePlatform()) {
    const onboardingCarouselRoute = {
      page: INTERNAL_ROUTES.ONBOARDING_CAROUSEL,
      path: `:countryCode/:lang${INTERNAL_ROUTES.ONBOARDING_CAROUSEL}`,
      component: <OnboardingCarousel />,
      isProtected: false,
    }

    dynamicRoutes.push(onboardingCarouselRoute)
  }

  return dynamicRoutes
    .filter((item) => isWhitelistRoute(item.page))
    .map((item) => (
      <Route
        key={String(item.path)}
        path={item.path}
        element={
          <WebRoute
            element={item}
            subscriberStatus={subscriberStatus}
            blockedRoutes={blockedRoutes}
            isLoggedIn={isLoggedIn}
            reviewChecks={reviewChecks}
          ></WebRoute>
        }
      ></Route>
    ))
}

export default function AppRoutes() {
  useLanguageManager()
  const { isLoggedIn, reviewChecks, setReviewChecks } = useAppState()
  const { data: subscriberData } = useSubscriber()
  const blockedRoutes = settings?.blockedRoutes
  let subscriberStatus = subscriberData?.currentSubscriber?.status
  let reviewNewChecks = subscriberData?.currentSubscriber?.reviewNewChecks
  const { updateWarningVisible, updateIsMandatory, dismissPopUp } =
    useAppNeedsUpdate()

  if (reviewNewChecks === true) {
    setReviewChecks(true)
  } else if (reviewNewChecks === false) {
    setReviewChecks(false)
  }
  moment.locale(i18n.language)

  !isLoggedIn && (subscriberStatus = SUBSCRIBER_STATUS.NOT_LOGGED)

  if (updateWarningVisible) {
    return (
      <UpdatePopup
        updateIsMandatory={updateIsMandatory}
        setOpen={dismissPopUp}
      />
    )
  }

  return (
    <Routes>
      {settings
        ? GetRoutes(subscriberStatus, blockedRoutes, isLoggedIn, reviewChecks)
        : null}
    </Routes>
  )
}
