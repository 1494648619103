import { gql, useQuery } from '@apollo/client'
import useAppState from './useAppState'
import { useMemo } from 'react'

export const CURRENT_SUBSCRIBER_QUERY = gql`
  {
    currentSubscriber {
      id
      firstName
      middleName
      lastName
      lastName2
      phoneNumber
      email
      gender
      status
      profileImageUrl
      driverLicenseFrontImageUrl
      driverLicenseBackImageUrl
      idFrontImageUrl
      idBackImageUrl
      birthdate
      createdAt
      documents {
        number
        type
        expiration
        isIdentityDocument
        idAuthenticationStatus
      }
      numberOfSubscriptions
      numberOfSubscriptionsCancel
      hoursOnSubscription
      driversLicense {
        state
        country
        expiration
        number
        dlAuthenticationStatus
      }
      reviewNewChecks
      registrationChecks {
        key
        value
      }
      communicationChannels {
        key
        value
      }
      subscription {
        id
        originDate
        start
        end
        duration
        status
        isReturnExpected
        isRenewable
        isSwap
        rate {
          rate
        }
        pickup {
          date
          location {
            id
            name
            latitude
            longitude
            openHours {
              mon {
                start
                end
              }
              tue {
                start
                end
              }
              wed {
                start
                end
              }
              thu {
                start
                end
              }
              fri {
                start
                end
              }
              sat {
                start
                end
              }
              sun {
                start
                end
              }
            }
          }
        }
        vehicle {
          id
          year
          make
          model
          trim
          dealer {
            id
            name
          }
          location {
            id
            phoneNumber
            latitude
            longitude
            address {
              address1
              address2
              city
              state
              zip
              country
            }
            timezone
            staticMapUrl
          }
          featureImage {
            url
          }
          commonPricing {
            pricing {
              value
              duration
              durationUnit
              bestTerm {
                term
                discountPercentage
                additionalPrice
              }
              productType
            }
          }
        }
        subscriptionTermPackage {
          term
          initialTermPackageDate
          discountPercentage
          endTermPackageDate
        }
        mileageTermPackage {
          isDefaultAdditionalRate
          mileage
          additionalPrice
        }
        fees {
          category
          isTaxable
          name
          qty
          type
          value
        }
        taxes {
          total
          taxes {
            amount
            description
            rate
          }
        }
      }
      address {
        address1
        city
        state
        country
        zip
        customFields
      }
      creditCheck
      data
    }
  }
`
const formatSubscriberData = (subscriberData) => {
  const subscriber = subscriberData?.currentSubscriber
    ? {
        ...subscriberData.currentSubscriber,
        ...subscriberData.currentSubscriber.data,
      }
    : null

  return subscriber
}

export default function useSubscriber() {
  const { isLoggedIn } = useAppState()

  const { data, loading, refetch } = useQuery(CURRENT_SUBSCRIBER_QUERY, {
    skip: !isLoggedIn,
  })

  const currentSubscriber = useMemo(() => {
    return formatSubscriberData(data)
  }, [data?.currentSubscriber])

  const refetchSubscriber = useMemo(() => {
    return async () => {
      const subscriberData = await refetch()
      return formatSubscriberData(subscriberData.data)
    }
  }, [refetch])

  return {
    loading,
    refetch: refetchSubscriber,
    data: { currentSubscriber },
  }
}
